import { apiClient as client } from '@/shared/service/api-client';

export default {
    // 直播课
    async searchRoom(params) {
        return await client.get("/live/room/search", { params });
    },
    async createRoom(params) {
        return await client.post("/live/room/create", params);
    },
    async updateRoom(params) {
        return await client.post("/live/room/update", params);
    },
    async deleteRoom(params) {
        return await client.post("/live/room/delete", params);
    },
    async resetExternalTeacher(params) {
        return await client.post("/live/room/resetExternalTeacher", params);
    },
    async uploadImg(params) {
        return await client.post("/live/room/uploadLiveCover", params);
    },

    // 直播数据
    async infoRoom(params) {
        return await client.get("/live/room/info", { params });
    },
    async searchStudent(params) {
        return await client.get("/live/room/student/search", { params });
    },
    async asyncExport(params) {
        return await client.get("/live/room/asyncExport", { params });
    },
    async getLastExportStatus(params) {
        return await client.get("/live/room/getLastExportStatus", { params });
    },
    async download(params) {
        return await client.get("/live/room/download", { params });
    },

    // pc进入直播间
    async getEntryInfo(params) {
        return await client.get("/live/room/getEntryInfo", { params });
    },
    async getEntryAuthRes(params) {
        return await client.get("/live/room/getEntryAuthRes", { params });
    },

    // 直播配置
    async timeUsage() {
        return await client.get("/live/account/time/usage");
    },
    async rechargeList(params) {
        return await client.get("/live/account/recharge/list", { params });
    },
    async billList(params) {
        return await client.get("/live/account/bill/list", { params });
    },
    async isOpenLiveAccount() {
        return await client.get("/live/account/isOpenLiveAccount");
    },
    async createAccount(params) {
        return await client.post("/live/account/create", params);
    },

    // 直播充值
    async priceConfig() {
        return await client.get("/live/account/priceConfig");
    },
    async isFirstOrder() {
        return await client.get("/live/account/order/isFirst");
    },

    // 更多设置
    async getPlayToken(params) {
        return await client.get("/live/room/getPlayToken", { params });
    },
    async getConvertStatus(params) {
        return await client.get("/live/room/getConvertStatus", { params });
    },

    // 课件上传
    async saveCourseware(params) {
        return await client.post("/live/room/saveCourseware", params);
    },
    async deleteCourseware(params) {
        return await client.post("/live/room/deleteCourseware", params);
    },
    async coursewareStartUpload(params) {
        return await client.post("/live/room/courseware/startUpload", params);
    },
    async coursewareFinishUpload(params) {
        return await client.post("/live/room/courseware/finishUpload", params);
    },
    async getAllCourseware(params) {
        return await client.get("/live/room/getAllCourseware", { params });
    },

    // 预告片上传
    async uploadTrailer(params) {
        return await client.post("/live/room/uploadTrailer", params);
    },
    async saveTrailer(params) {
        return await client.post("/live/room/saveTrailer", params);
    },
    async deleteTrailer(params) {
        return await client.post("/live/room/deleteTrailer", params);
    },
    async getVideoStatus(params) {
        return await client.get("/live/room/getVideoStatus", { params });
    },
    async getTrailerPlayUrl(params) {
        return await client.get("/live/room/getTrailerPlayUrl", { params });
    },

    // 课件设置相关配置
    async updateTeacher(params) {
        return await client.post("/live/room/updateTeacher", params);
    },
    async updateAssistant(params) {
        return await client.post("/live/room/updateAssistant", params);
    },
    async updateGoods(params) {
        return await client.post("/live/room/updateGoods", params);
    },
    async deleteLiveGoods(params) {
        return await client.post("/live/room/deleteLiveGoods", params);
    },
    async updateProductIsopen(params) {
        return await client.post("/live/room/updateProductIsopen", params);
    },

    // 运营设置相关配置项
    async settingSave(params) {
        return await client.post("/live/room/setting/save", params);
    },
    async settingFind(params) {
        return await client.get("/live/room/setting/find", { params });
    },

    // 直播分发
    async memberShareLive(params) {
        return await client.post("/live/room/memberShareLive", params);
    },
    async checkMemberShareLive(params) {
        return await client.get("/live/room/checkMemberShareLive", { params });
    },
    async getMemberShareLive(params) {
        return await client.get("/live/room/getMemberShareLive", { params });
    },

    // 直播专栏
    async searchLiveRoomSet(params) {
        return await client.get("/live/roomSet/search", { params });
    },
    async createLiveRoomSet(params) {
        return await client.post("/live/roomSet/create", params);
    },
    async updateLiveRoomSet(params) {
        return await client.post("/live/roomSet/update", params);
    },
    async deleteLiveRoomSet(params) {
        return await client.post("/live/roomSet/delete", params);
    },
    async getLiveRoomSet(params) {
        return await client.get("/live/roomSet/getLiveRoomSet", { params });
    },
    async getSetStudentList(params) {
        return await client.get("/live/roomSet/getSetStudentList", { params });
    },
    async asyncSetExport(params) {
        return await client.get("/live/roomSet/asyncExport", { params });
    },
    async getSetLastExportStatus(params) {
        return await client.get("/live/roomSet/getLastExportStatus", { params });
    },
}